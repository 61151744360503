import { useState, createContext } from 'react'

const BackgroundMusicContext = createContext()

export const BackgroundMusicProvider = ({ children }) => {
  const [isMusicMuted, setIsMusicMuted] = useState(false)
  const [musicError, setMusicError] = useState(false)
  const [isCasting, setIsCasting] = useState(false)
  const [castToken, setCastToken] = useState(null)
  const [castId, setCastId] = useState(null)

  return (
    <BackgroundMusicContext.Provider
      value={{
        isMusicMuted,
        setIsMusicMuted,
        musicError,
        setMusicError,
        isCasting,
        setIsCasting,
        castToken,
        setCastToken,
        castId,
        setCastId
      }}
    >
      {children}
    </BackgroundMusicContext.Provider>
  )
}

export default BackgroundMusicContext
